class TabMainGroup {
    constructor(element) {
        this.element = element
        this.ref = {
            linkContainer: null,
        }

        this.element.classList.add("is-ready")
    }

    prepare() {
        $(this.element).on("change", this.handleChange)

        setTimeout(() => {
            this.updateTypicalProductOptions()
            this.updateLinks()
        }, 0)
    }

    handleChange = (event) => {
        if (event.target.name === "variant") {
            //az po zmene nettetoggle
            clearTimeout(this.timer1)
            this.timer1 = setTimeout(() => {
                this.updateTypicalProductOptions()
                this.updateLinks()
            }, 300)
        }

        if (event.target.name === "variant_products[]" || event.target.name === "products[]") {
            clearTimeout(this.timer1)
            this.timer1 = setTimeout(() => {
                this.updateTypicalProductOptions()
                this.updateLinks()
            }, 300)
        }

        if (event.target.name === "product_id") {
            clearTimeout(this.timer2)
            this.timer2 = setTimeout(() => {
                this.updateHeliosData()
            }, 300)
        }
    }

    //omezit vyber typickyho produktu pouze na ty, ktery sou prevybrany/v tyhle skupine
    updateTypicalProductOptions() {
        const typicalProductEl = this.element.querySelector('[name="product_id"]')
        let productsEl = null
        if (getComputedStyle(document.getElementById("select-products")).display !== "none") {
            productsEl = this.element.querySelector('[name="products[]"]')
        }
        if (getComputedStyle(document.getElementById("select-variant-products")).display !== "none") {
            productsEl = this.element.querySelector('[name="variant_products[]"]')
        }

        const selectedProductIds = [...productsEl.selectedOptions].map((option) => option.value)
        const typicalProductOptions = [...typicalProductEl.options]

        typicalProductOptions.forEach((option) => {
            const value = option.value
            if (selectedProductIds.includes(value) || value === "") {
                option.disabled = false
            } else {
                option.disabled = true
            }
        })

        if (!selectedProductIds.includes(typicalProductEl.value)) {
            typicalProductEl.value = typicalProductEl.options[0].value
        }

        $(typicalProductEl).trigger("chosen:updated")
    }

    //vytvorit linky na produkty ze selectu
    updateLinks() {
        const typicalProductEl = this.element.querySelector('[name="product_id"]')
        let productsEl = null
        if (getComputedStyle(document.getElementById("select-products")).display !== "none") {
            productsEl = this.element.querySelector('[name="products[]"]')
        }
        if (getComputedStyle(document.getElementById("select-variant-products")).display !== "none") {
            productsEl = this.element.querySelector('[name="variant_products[]"]')
        }
        let container = null
        let linkTemplate = null
        let selectedOptions = null
        let html = null

        //linky pro products
        container = this.element.querySelector("[data-productslinks]")
        linkTemplate = this.element.querySelector("[data-productslinks-template]").innerHTML
        selectedOptions = [...productsEl.selectedOptions]
        html = selectedOptions
            .map((option) => {
                const html = `${linkTemplate}`.replace("999999999999999999", option.value)
                return html
            })
            .join("")
        container.innerHTML = html

        //linky pro typicky produkt
        container = this.element.querySelector("[data-typicalproductlinks]")
        linkTemplate = this.element.querySelector("[data-typicalproductlinks-template]").innerHTML
        selectedOptions = [...typicalProductEl.selectedOptions]
        html = selectedOptions
            .map((option) => {
                const html = `${linkTemplate}`.replace("999999999999999999", option.value)
                return html
            })
            .join("")
        container.innerHTML = html
    }

    updateHeliosData() {
        const typicalProductEl = this.element.querySelector('[name="product_id"]')
        const value = typicalProductEl.value
        const href = getInitialHrefFromElement(this.ref.linkHeliosData)
        this.ref.linkHeliosData.href = href.replace("999999999999999999", value)
        this.ref.linkHeliosData.click()
    }
}

function getInitialHrefFromElement(element) {
    if (element.dataset.href === undefined) {
        element.dataset.href = element.href
    }
    return element.dataset.href
}

;(function () {
    function loadComponents(ctx = document) {
        const elements = [...ctx.querySelectorAll('[data-component="TabMainGroup"]:not(.is-ready)')]
        elements.forEach((element) => {
            const component = new TabMainGroup(element)
            component.prepare()
        })
    }

    loadComponents()

    document.addEventListener("cloneradd", (event) => {
        const { ctx } = event.detail
        loadComponents(ctx)
    })
})()
