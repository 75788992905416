class CharCount {
    constructor(element) {
        this.element = element
        this.ref = {
            input: this.element.querySelector('[data-ref="input"]'),
            counter: this.element.querySelector('[data-ref="counter"]'),
        }

        this.maxCount = this.element.dataset.maxCount ?? 40
    }

    prepare() {
        this.ref.input.addEventListener('input', this.handleInputInput)
        setTimeout(() => {
            this.updateCounter()
            this.element.classList.add('is-ready')
        }, 0);
    }

    handleInputInput = () => {
        this.updateCounter()
    }

    updateCounter() {
        this.ref.counter.innerHTML = `${this.ref.input.value.length}/${this.maxCount}`
    }   
}

(function () {
    function loadComponents(ctx = document) {
        const elements = [...ctx.querySelectorAll('[data-component="CharCount"]:not(.is-ready)')]
        elements.forEach(element => {
            const component = new CharCount(element)
            component.prepare()
        })
    }

    loadComponents()

    document.addEventListener('cloneradd', event => {
        const {ctx} = event.detail
        loadComponents(ctx)
    })
})()
