import 'console-polyfill'

import './core/Sentry'

import $ from 'jquery'

import 'chosen-js'

// jquery utils
import './plugins/nette.ajax'
// import './plugins/history.ajax';
import './plugins/jquery.ui.sortable'
import 'bootstrap'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.cs'
import 'ublaboo-datagrid'
// import 'ublaboo-datagrid/assets/datagrid-instant-url-refresh'
import 'ublaboo-datagrid/assets/datagrid-spinners'

// plugins
import './plugins/giant.autosubmit'
import './plugins/fileinput'
import './plugins/giant.ckeditor'
import './plugins/giant.datepicker'
import './plugins/giant.sortable'
import './plugins/giant.image-area'
import './plugins/substitute'

// components
import '../components/giant-auto-url'
import '../components/giant.batch-operation'
import '../components/giant.chosen'
import '../components/giant-cloner'
import '../components/giant.help'
import '../components/giant.item-selector'
import '../components/giant-show-if'
import '../components/giant.switcher'
import '../components/giant.tree'
import '../components/giant.tree-selector'
import '../components/giant-filemanager'

// modules
import './modules/checkbox-toggle'
import './modules/data-confirm'
import './modules/filter'
import './modules/nav'
import './modules/trigger'
import './modules/category-products'

// components
import './components/CharCount';
import './components/GpsLocator';
import './components/TabMainGroup';
import './components/TabParameters';

import Switchery from 'switchery/standalone/switchery'

const { forEach } = Array.prototype

// nette
import Nette from './nette/NetteForms/netteForms.js'
Nette.initOnLoad()
window.Nette = Nette

if ($.nette) {
    $.nette.init()

    $.nette.ext('update', {
        success: function (payload) {
            if (payload.snippets) {
                Object.keys(payload.snippets).forEach(function (elementId) {
                    $('#' + elementId).trigger('dom:change')
                })
            }
        },
    })
}

document.querySelectorAll('.js-switch')::forEach((element) => {
    new Switchery(element, {
        color: '#1c84c6',
        size: 'small',
    })
})