import { nanoid } from 'nanoid'

const modalTemplate = `
    <div class="fm-Modal" tabindex="-1" role="dialog">
      <div class="fm-Modal-inner">
        <div class="fm-Modal-content">
            <giant-filemanager select="multiple" />
        </div>
      </div>
    </div>
`

const defaults = {
    className: '',
    onSelect: function () { },
    onCancel: function () { },
    onClose: function() { }
}

class FmModal {
    constructor(options = {}) {
        this.options = {
            ...defaults,
            ...options
        }

        this.element = null
    }

    build() {
        const buffer = document.createElement('div')
        buffer.innerHTML = modalTemplate.trim()
        this.element = buffer.firstChild
        document.body.appendChild(this.element)

        this.ref = {
            filemanager: this.element.querySelector('giant-filemanager')
        }

        this.element.addEventListener('filemanager:select', this.handleSelect)
        this.element.addEventListener('filemanager:cancel', this.handleCancel)
    }

    open(options = {}) {
        this.options = {
            ...this.options,
            ...options
        }

        if (!this.element) {
            this.build()
        }

        this.applyOptions()

        this.element.classList.add('is-visible')
        document.documentElement.style.overflow = 'hidden'
    }

    close() {
        this.element.classList.remove('is-visible')
        document.documentElement.style.overflow = 'visible'

        this.options?.onClose({
            id: this.options.id
        })
    }

    applyOptions() {
        ['select', 'folder', 'accept'].forEach(attribute => {
            if (attribute in this.options) {
                if (this.options[attribute]) {
                    this.ref.filemanager.setAttribute(attribute, this.options[attribute])
                } else {
                    this.ref.filemanager.removeAttribute(attribute)
                }
            }
        })
    }

    handleSelect = event => {
        this.options?.onSelect({
            id: this.options.id,
            data: event.detail
        })
        this.close()
    }

    handleCancel = () => {
        this.options?.onCancel({
            id: this.options.id
        })
        this.close()
    }
}

let modalInstance = null

export function openModal(options = {}) {
    let { id } = options
    if (!id) {
        id = nanoid()
    }

    options.id = id

    if (!modalInstance) {
        modalInstance = new FmModal(options)
        modalInstance.open()
    } else {
        modalInstance.open(options)
    }

    return id
}

export function closeModal() {
    if (modalInstance) {
        modalInstance.close()
    }
}

window.addEventListener('fm:modal:open', event => {
    openModal(event.detail)
})
