// jen binding, vyzaduje chosen.js pres bower
(function() {

    var attachChosen = function(context) {
        var $items = $('select[multiple]:not([data-tree-selector]), [data-chosen]', context || document.body)
        $items.filter(function(index) {
            return !this.closest('.template')
        }).chosen({
            placeholder_text_multiple: 'Vyberte',
            placeholder_text_single: 'Vyberte',
            disable_search_threshold: '5',
        });

        $items.each(function() {
            attachQuickSelectAll($(this))
        })
    }

    attachChosen();

    $(document).on('dom:change', function(event) {
        attachChosen(event.target);
    });

})();



function attachQuickSelectAll($element) {
    if (!$element || $element.length == 0 || $element.attr('multiple') === undefined) {
        return
    }

    var $placeholderEl = null
    var $selectAllOptionEl = null

    function isAllChecked() {
        return $element[0].selectedOptions.length === $element[0].options.length
    }

    function showPlaceholder() {
        if ($placeholderEl) {
            return
        }
        $placeholderEl = $('<div class="chosenquickselectall-placeholder">- vybráno vše -</div>')
        $placeholderEl.on('click', function() {
            hidePlaceholder()
        })

        var $containerEl = $element.parent().find('.chosen-container')
        $containerEl.addClass('chosenquickselectall-active')
        $containerEl.append($placeholderEl)
    }

    function hidePlaceholder() {
        if (!$placeholderEl) {
            return
        }
        $placeholderEl.remove()
        $placeholderEl = null

        var $containerEl = $element.parent().find('.chosen-container')
        $containerEl.removeClass('chosenquickselectall-active')
    }

    function checkIfAllSelected() {
        if (isAllChecked()) {
            showPlaceholder()
        } else {
            hidePlaceholder()
        }
    }

    function onDropdownShow() {
        if (isAllChecked()) {
            return
        }

        $selectAllOptionEl = $('<li class="chosenquickselectall-option">Vybrat vše</li>')
        $selectAllOptionEl.on('click', function() {
            $element.find('option').prop('selected', true);
            $element.trigger('change')
            $element.trigger('chosen:updated');
        })
        $(this).parent().find('.chosen-results').prepend($selectAllOptionEl)
    }

    function onDropdownHide() {
        if (!$selectAllOptionEl) {
            return
        }
        $selectAllOptionEl.remove()
    }

    checkIfAllSelected()

    $element.on('change', checkIfAllSelected)
    $element.on('chosen:showing_dropdown', onDropdownShow)
    $element.on('chosen:hiding_dropdown', onDropdownHide)
}