import debounce from 'lodash/debounce'
class TabParameters {
    constructor(element) {
        this.element = element
    }

    prepare() {
        document.addEventListener('cloneradd', this.handleClonerChange)
        document.addEventListener('clonerremove', this.handleClonerChange)
        $(this.element).on('change', debounce(this.handleChange,500))
        this.updateParamSelects()
    }

    handleChange = () => {
        this.updateParamSelects()
    }

    handleClonerChange = event => {
        const {ctx} = event.detail
        if (!this.element.contains(ctx)) {
            return
        }

        setTimeout(() => {
            this.updateParamSelects()
        }, 0);
    }

    //disablovat optiony, ktery sou uz pouzity
    updateParamSelects() {
        const paramAdd = this.element.querySelector('[data-ref="paramAdd"]')
        const paramSelects = [...this.element.querySelectorAll('[data-ref="paramSelect"]')]
        const usedValues = paramSelects.map(paramSelect => paramSelect.value)
        let optionsCount = 0

        paramSelects.forEach(paramSelect => {
            const selectedValue = paramSelect.value
            const options = [...paramSelect.options]
            optionsCount = options.length

            options.forEach(option => {
                if (option.value === selectedValue) {
                    return
                }

                if (usedValues.includes(option.value) && option.value !== '') {
                    option.disabled = true
                } else {
                    option.disabled = false
                }
            })
            $(paramSelect).trigger('chosen:updated')
        })

        if (paramSelects.length && optionsCount - 1 <= paramSelects.length) {
            paramAdd.style.display = 'none'
        } else {
            paramAdd.style.display = 'block'
        }
    }
}

(function () {
    function loadComponents(ctx = document) {
        const elements = [...ctx.querySelectorAll('[data-component="TabParameters"]:not(.is-ready)')]
        elements.forEach(element => {
            const component = new TabParameters(element)
            component.prepare()
        })
    }

    loadComponents()

    document.addEventListener('cloneradd', event => {
        const {ctx} = event.detail
        loadComponents(ctx)
    })
})()
