/**
 * Initialization of sortable to provide automatic data-binding and data-configuration.
 */
var Giant = Giant || {};

;(function($, window, document, undefined) {

    var pluginName = 'giantSortable',
        dataPrefix = 'sortable',
        $window = $(window),
        defaults = {
            handle: '.handle',
            placeholder: 'sortable-placeholder',
            item: 'tr',
            data: {},
            name: 'order'
        };

    function Sortable(element, options) {
        this.scope = element;
        this.$scope = $(element);

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    Sortable.prototype = {

        init: function() {
            var self = this;


            this.options.helper = function(event, ui) {
                ui.children().each(function() {
                    $(this).width($(this).width());
                });

                return ui;
            }
            this.options.update = this.save.bind(this);

            this.attach();

            return this;
        },

        save: function() {

            if (!this.options.url) {
                return this;
            }

            var order = this.$scope.find(this.options.item).toArray().map(function(item) {
                return $(item).data('id');
            });

            var orderData = {};
            orderData[this.options.name] = order;

            var data = $.extend({}, this.options.data, orderData);

            $.ajax({
                url: this.options.url,
                data: data,
                error: function() {
                    alert('Pořadí se nepodařilo uložit. Zkuste stránku obnovit a akci opakovat.');
                }
            });

            return this;

        },

        attach: function() {
            this.$scope.sortable(this.options);
        },

        detach: function() {

        }

    };

    $.fn[pluginName] = function (options) {
        var dataPrefixLength = dataPrefix.length;

        return this.each(function () {
            if (!$.data(this, 'plugin-' + pluginName)) {

                // parse data-options
                var data = $(this).data(),
                    options = {},
                    settings = '';

                if (pluginName in data) {
                    settings = data[pluginName];
                    // pass options
                    if ($.type(settings) === 'object') {
                        options = settings;
                    }
                }

                // pass all options to plugin
                $.each(data, function(key, value) {
                    if (key.substr(0, dataPrefixLength) === dataPrefix && key !== dataPrefix) {
                        var id = key.substr(dataPrefixLength);
                        id = id.substr(0,1).toLowerCase() + id.substr(1);
                        options[id] = value;
                    }
                });

                $.data(this, 'plugin-' + pluginName, new Sortable(this, options));
            }
        });
    };

    $.fn[pluginName].defaults = defaults;

    // listen to change event and init self
    $(document).on('dom:change', function(event) {
        $('[data-sortable]', event.target).giantSortable();
    });
    $('[data-sortable]').giantSortable();

    Giant.Sortable = Sortable;

})(jQuery, window, document);