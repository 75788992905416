var Giant = Giant || {};

;(function($, window, document, undefined) {

    var pluginName = 'giantSwitcher',
        dataPrefix = 'switcher',
        $window = $(window),
        defaults = {
            confirmUncheck: false
        };

    function Switcher(element, options) {
        this.scope = element;
        this.$scope = $(element);

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    Switcher.prototype = {

        init: function() {
            var self = this;

            this.attach();

            return this;
        },

        attach: function() {
            var self = this,
                checked = this.$scope.prop('checked');

            var $wrap = $('<div class="switcher__wrap' + (this.$scope[0].disabled ? ' is-disabled' : '') + '"></div>');
            this.$scope.wrap($wrap);
            this.$scope.after('<div class="switcher' + (checked ? ' switcher--checked' : '') +'"><span class="switcher__text">' + (checked ? 'Ano' : 'Ne') + '</span><div class="switcher__knob"></div></div>');

            var update = function() {
                var wasChecked = checked
                var $switcher = self.$scope.parent().find('.switcher'),
                    $input = self.$scope,
                    isChecked = $input.prop('checked'),
                    $text = $switcher.find('.switcher__text');

                if (wasChecked === isChecked) {
                    return
                }

                checked = isChecked
                triggerEvent($input[0], 'change')

                if (!isChecked) {
                    if (!self.options.confirmUncheck || (self.options.confirmUncheck && confirm(self.options.confirmUncheck))) {
                        $text.text('Ne');
                        $switcher.removeClass('switcher--checked');
                    }
                } else {
                    $text.text('Ano');
                    $switcher.addClass('switcher--checked');
                }
            }

            this.$scope.parent().find('.switcher').on('click', function(event) {
                self.$scope.prop('checked', !self.$scope.prop('checked')).trigger('change')
            });

            this.$scope.on('change', function(params) {
                update()
            })
        },

        detach: function() {
            // this.$scope.off('');
        }

    };

    $.fn[pluginName] = function (options) {
        var dataPrefixLength = dataPrefix.length;

        return this.each(function () {
            if (!$.data(this, 'plugin-' + pluginName)) {

                // parse data-options
                var data = $(this).data(),
                    options = {},
                    settings = '';

                if (pluginName in data) {
                    settings = data[pluginName];
                    // pass options
                    if ($.type(settings) === 'object') {
                        options = settings;
                    }
                }

                // pass all options to plugin
                $.each(data, function(key, value) {
                    if (key.substr(0, dataPrefixLength) === dataPrefix && key !== dataPrefix) {
                        var id = key.substr(dataPrefixLength);
                        id = id.substr(0,1).toLowerCase() + id.substr(1);
                        options[id] = value;
                    }
                });

                $.data(this, 'plugin-' + pluginName, new Switcher(this, options));
            }
        });
    };

    $.fn[pluginName].defaults = defaults;

    // listen to change event and init self
    $(document).on('dom:change', function(event) {
        $('[data-switcher]', event.target).giantSwitcher();
    });
    $('[data-switcher]').giantSwitcher();

    Giant.Switcher = Switcher;

})(jQuery, window, document);



function triggerEvent(
    element,
    eventType,
    params = null,
    options = {
        bubbles: true,
        cancelable: true,
        detail: null
    }) {
    options.detail = params;
    const event = new CustomEvent(eventType, options);
    element.dispatchEvent(event);
}
