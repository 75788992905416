import { Loader } from 'google-maps'

const GOOGLE_MAPS_API_KEY = 'AIzaSyCA5BLTKvNJqrHibQvstE1Trx9VubtI5Zo'

const options = {
    libraries: ['places'],
    language: 'cs',
    region: 'EU'
}

const gmapsLoader = new Loader(GOOGLE_MAPS_API_KEY, options)

class GpsLocator {
    constructor(element) {
        this.element = element
        this.ref = {
            inputAutocomplete: this.element.querySelector('[data-ref="inputAutocomplete"]'),
            inputLat: this.element.querySelector('[data-ref="inputLat"]'),
            inputLng: this.element.querySelector('[data-ref="inputLng"]'),
            inputPlaceData: this.element.querySelector('[data-ref="inputPlaceData"]'),
            map: this.element.querySelector('[data-ref="map"]')
        }

        this.autocomplete = null
        this.placeData = null
        this.observer = new IntersectionObserver(this.handleIntersection, {
            threshold: 0
        })
    }

    prepare() {
        this.observer.observe(this.element)

        if (this.ref.inputLat) {
            this.ref.inputLat.addEventListener('input', this.handleInputChange)
        }
        if (this.ref.inputLng) {
            this.ref.inputLng.addEventListener('input', this.handleInputChange)
        }
        if (this.ref.inputPlaceData) {
            this.ref.inputPlaceData.addEventListener('input', this.handleInputChange)
        }

        this.element.classList.add('is-ready')
    }

    handleIntersection = entries => {
        entries.forEach(entry => {
            if (entry.target !== this.element) {
                return
            }
            if (entry.isIntersecting) {
                this.setup()
                this.observer.disconnect()
            }
        })
    }

    handlePlaceChange = () => {
        const place = this.autocomplete.getPlace()
        if (!place.geometry) {
            return
        }

        this.placeData = {
            location: place.geometry.location,
            placeId: place.place_id
        }

        if (this.ref.inputPlaceData) {
            this.ref.inputPlaceData.value = JSON.stringify(this.placeData)
        }

        if (this.ref.inputLat) {
            this.ref.inputLat.value = this.placeData.location.lat()
        }

        if (this.ref.inputLng) {
            this.ref.inputLng.value = this.placeData.location.lng()
        }

        this.showMarker(place.geometry.location)
    }

    handleInputChange = () => {
        this.placeData = this.getInitialPlaceData()
        this.showMarker(this.placeData.location)
    }

    async setup() {
        if (!window.google) {
            await gmapsLoader.load()
        }

        this.autocomplete = new google.maps.places.Autocomplete(this.ref.inputAutocomplete, {
            // types: ['geocode'],
            componentRestrictions: { country: 'cz' }
        })

        window.google.maps.event.addDomListener(this.ref.inputAutocomplete, 'keydown', function (event) {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        })

        this.autocomplete.addListener('place_changed', this.handlePlaceChange)

        this.placeData = this.getInitialPlaceData()

        if (this.placeData.location) {
            this.showMarker(this.placeData.location)
        }
    }

    initMap(location) {
        if (!location.lat || !location.lng) {
            return
        }

        this.map = new window.google.maps.Map(this.ref.map, {
            zoom: 10,
            center: location,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
        })

        this.element.classList.add('is-map-visible')
    }

    showMarker(location) {
        if (!this.map) {
            this.initMap(location)
        }

        if (!this.map) {
            return
        }

        if (this.marker) {
            this.marker.setMap(null)
        }

        this.map.setCenter(location)
        this.marker = new window.google.maps.Marker({
            position: location,
            map: this.map
        })
    }

    getInitialPlaceData() {
        let placeData = {
            location: {
                lat: null,
                lng: null
            }
        }

        if(this.ref.inputPlaceData) {
            placeData = JSON.stringify(this.ref.inputPlaceData.value || '{}')
        }

        if (this.ref.inputLat) {
            placeData.location.lat = parseFloat(this.ref.inputLat.value)
        }

        if (this.ref.inputLng) {
            placeData.location.lng = parseFloat(this.ref.inputLng.value)
        }

        return placeData
    }
}

(function () {
    function loadComponents(ctx = document) {
        const elements = [...ctx.querySelectorAll('[data-component="GpsLocator"]:not(.is-ready)')]
        elements.forEach(element => {
            const component = new GpsLocator(element)
            component.prepare()
        })
    }

    loadComponents()

    document.addEventListener('cloneradd', event => {
        const {ctx} = event.detail
        loadComponents(ctx)
    })
})()
